import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import { getFirstLetter } from '@utils/index';
import { useForm } from '@hooks/helpers/useForm';
import { defaultProfileSettingsData } from '@const/default_form_data';
import { FormProvider } from 'react-hook-form';
import PersonalSection from '@sections/settings/personal';
import AddressSection from '@sections/settings/address';
import { zodResolver } from '@hookform/resolvers/zod';
import { profileSettingsValidation } from '@helpers/validation_objects/profile_settings';
import { EAddressType, TIndividualAddress, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';

const ProfileLayout = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.personal'
    });
    const { user, refetchUser } = useUserContext();
    const { onOpen, onClose } = useModalContext();
    const { validationLocale, labelLocale, requestErrorLocale, requestSuccessLocale } =
        useLocales();
    const formMethods = useForm<TIndividualProfile>({
        defaultValues: defaultProfileSettingsData,
        resolver: zodResolver(
            profileSettingsValidation({
                messages: {
                    email: validationLocale('email'),
                    phone: validationLocale('phone'),
                    firstName: validationLocale('firstName'),
                    lastName: validationLocale('lastName'),
                    birthDate: validationLocale('birthDate'),
                    occupancy: validationLocale('occupancy')
                }
            })
        )
    });

    useEffect(() => {
        if (user) {
            formMethods.reset(user);
        }
    }, [user]);

    const { data } = useApiQuery({
        method: () => apiUserService.getAddress()
    });

    const handleChangeAvatar = () => {
        onOpen({
            modalId: modalIds.SETTINGS_UPLOAD_IMAGE,
            onSubmit: (data: { avatar: string }) => {
                apiUserService
                    .updateAvatar(data.avatar)
                    .then(() => {
                        refetchUser();
                        requestSuccessLocale('update_avatar');
                        onClose();
                    })
                    .catch(requestErrorLocale);
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <div className={styles.avatar_wrapper}>
                        <div>
                            {user?.profile?.avatar ? (
                                <img src={user?.profile?.avatar} alt="your avatar" />
                            ) : (
                                <Typography variant="h3">
                                    {getFirstLetter(user?.profile?.firstName)}
                                    {getFirstLetter(user?.profile?.lastName)}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <button onClick={handleChangeAvatar}>
                                <Typography variant="body1">
                                    {t(user?.profile?.avatar ? 'update_avatar' : 'add_avatar')}
                                </Typography>
                            </button>
                            {user?.profile?.avatar && (
                                <button onClick={handleChangeAvatar}>
                                    <Typography variant="body1">{t('delete_avatar')}</Typography>
                                </button>
                            )}
                        </div>
                    </div>
                    <PersonalSection />
                    <AddressSection
                        label={labelLocale('billing_address')}
                        name="billingAddress"
                        type={EAddressType.BILLING}
                        data={data as TIndividualAddress}
                    />
                </div>
            </FormProvider>
        </div>
    );
};

export default ProfileLayout;
