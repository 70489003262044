import React from 'react';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useUserContext } from '@hooks/context/useUserContext';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';

const AccountsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.accounts'
    });
    const navigate = useNavigate();
    const { account, refetchAccount, isDataLoading } = useUserContext();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { onOpen, onClose } = useModalContext();
    const { verifiedAction } = useVerificationGuard();

    const handleOpenModal = () => {
        if (!!account?.bankAccounts.length) {
            verifiedAction(() => navigate(routes.link_card_bank));
        } else {
            verifiedAction(() =>
                onOpen({
                    modalId: modalIds.LINK_BANK_OR_CARD
                })
            );
        }
    };

    const handleOpenLinkInteracModal = () => {
        verifiedAction(() =>
            onOpen({
                modalId: modalIds.LINK_INTERAC_EMAIL,
                onSubmit: (data: { interac_email: string }) =>
                    apiAccountService
                        .linkETransferAccount(data?.interac_email)
                        .then(() => {
                            requestSuccessLocale('link_interac_email');
                            onClose();
                            refetchAccount();
                        })
                        .catch(requestErrorLocale)
            })
        );
    };

    return (
        <div className={styles.wrapper}>
            <Button
                onClick={() => verifiedAction(() => navigate(routes.add_money_balance))}
                className={styles.add_button}
                variant="primary"
                size="medium">
                {t('add_money')}
            </Button>
            <div className={styles.accounts}>
                <Typography variant="body1" weight="bold">
                    {t('title')}
                </Typography>
                {isDataLoading && <Skeleton className={styles.account_skeleton} />}
                {!!account?.bankAccounts.length && (
                    <ul className={styles.accounts_list}>
                        {account?.bankAccounts.map((item) => (
                            <li key={item.id} className={styles.account_item}>
                                <Typography variant="body3">{item.institutionName}</Typography>
                                <Typography variant="body3" fz={14}>
                                    {item.accountName} {item.accountNumber}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                )}
                <Button className={styles.add_card} onClick={handleOpenModal}>
                    <div>
                        <Icon name="plus" />
                    </div>
                    <Typography variant="h5" weight="bold">
                        {t('link_new')}
                    </Typography>
                </Button>
            </div>
            <div className={styles.accounts}>
                <Typography variant="body1" weight="bold">
                    {t('interac_title')}
                </Typography>
                {isDataLoading && <Skeleton className={styles.etransfer_skeleton} />}
                {account?.eTransferAccount?.interacEmail ? (
                    <ul className={styles.accounts_list}>
                        <li className={styles.account_item}>
                            <Typography variant="body3">
                                {account?.eTransferAccount?.interacEmail}
                            </Typography>
                        </li>
                    </ul>
                ) : (
                    <Button className={styles.add_card} onClick={handleOpenLinkInteracModal}>
                        <div>
                            <Icon name="plus" />
                        </div>
                        <Typography variant="h5" weight="bold">
                            {t('link_interac')}
                        </Typography>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AccountsSection;
