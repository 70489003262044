import React from 'react';
import Typography from '@components/common/typography';
import CardsSection from '@sections/xeppt_cards/cards';
import styles from './styles.module.scss';

const XepptCardsLayout = () => {
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                XEPPT Card
            </Typography>
            <div className={styles.content}>
                <CardsSection />
                {/*<OrderCardsSection />*/}
            </div>
        </div>
    );
};

export default XepptCardsLayout;
