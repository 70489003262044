import { getBirthDisabledDates, getDefaultPhoneCode } from '@utils/index';
import { EUserType } from '@xeppt/xeppt-sdk/types/user';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';

export const defaultAddressData = {
    country: '',
    city: '',
    address2: '',
    address1: '',
    zipCode: '',
    region: ''
};

export const defaultBusinessData = {
    companyName: '',
    tradingName: '',
    registrationNumber: '',
    category: '',
    size: '',
    website: ''
};

export const defaultPersonalInfo = {
    email: '',
    phoneNumber: '',
    phoneCode: getDefaultPhoneCode(),
    profile: {
        firstName: '',
        lastName: '',
        birthday: getBirthDisabledDates(),
        occupancy: ''
    },
    billingAddress: defaultAddressData
};

export const defaultPersonalSignUpData = {
    email: '',
    phoneNumber: '',
    phoneCode: getDefaultPhoneCode(),
    profile: {
        firstName: '',
        lastName: '',
        birthday: getBirthDisabledDates(),
        occupancy: ''
    },
    billingAddress: defaultAddressData,
    type: EUserType.INDIVIDUAL,
    verificationCode: ''
};

export const defaultBusinessSignUpData = {
    email: '',
    phoneNumber: getDefaultPhoneCode(),
    profile: {
        firstName: '',
        lastName: '',
        birthday: getBirthDisabledDates(),
        occupancy: '',
        residence: ''
    },
    businessAddress: defaultAddressData,
    registrationAddress: defaultAddressData,
    registrationSameBusiness: false,
    tradingAddress: defaultAddressData,
    tradingSameBusiness: false,
    type: EUserType.BUSINESS,
    verificationCode: '',
    business: {
        companyName: '',
        tradingName: '',
        registrationNumber: '',
        category: '',
        size: '',
        website: ''
    }
};

export const defaultSignInData = {
    email: '',
    password: ''
};

export const defaultForgotPassword = {
    email: '',
    phoneCode: getDefaultPhoneCode(),
    phoneNumber: '',
    password: '',
    passwordConfirm: ''
};

export const defaultProfileSettingsData = {
    ...defaultPersonalInfo,
    type: EUserType.BUSINESS
};

export const defaultLinkCard = {
    firstName: '',
    lastName: '',
    cardNumber: '',
    expire: '',
    code: ''
};

export const defaultLinkBank = {
    accountNumber: '',
    transitNumber: '',
    accountName: '',
    bankNumber: ''
};

export const addressValidationErrors = (localization: (value: string) => string) => ({
    address1: localization('address1'),
    address2: localization('address2'),
    zipCode: localization('zipCode'),
    city: localization('city'),
    country: localization('country'),
    region: localization('region')
});

export const defaultRequestCard = {
    type: ECardType.VIRTUAL,
    shippingAddress: defaultAddressData
};
