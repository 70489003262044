import {
    AccountService,
    AuthService,
    RegistrationService,
    UserService,
    WalletService,
    XepptClient
} from '@xeppt/xeppt-sdk';
import { routes } from '@const/routes';

export const sdk = new XepptClient(
    {
        baseURL: process.env.REACT_APP_BACKEND_URL
    },
    () => {
        window.location.replace(routes.signin);
    }
);

export const apiRegistrationService = new RegistrationService(sdk);
export const apiAuthService = new AuthService(sdk);
export const apiUserService = new UserService(sdk);
export const apiWalletService = new WalletService(sdk);
export const apiAccountService = new AccountService(sdk);
