import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import DatePicker from '@components/date_picker';
import { useUserContext } from '@hooks/context/useUserContext';

const ThirdStep = () => {
    const { labelLocale } = useLocales();
    const { user } = useUserContext()
    return (
        <div className={styles.wrapper}>
            <div className={styles.column}>
                <div className={styles.row}>
                    <Input disabled value={user?.profile.firstName} full label={labelLocale('first_name')} />
                    <Input disabled value={user?.profile.lastName} full label={labelLocale('last_name')} />
                </div>
                <DatePicker disabled value={user?.profile.birthDate as string | undefined} full label={labelLocale('birth_date')} />
                {/*<Input value={user?.profile} full label={labelLocale('occupation')} />*/}
            </div>
            <Typography variant="body1">{labelLocale('shipping_address')}</Typography>
            <Address name="shippingAddress" />
        </div>
    );
};

export default ThirdStep;
