import React, { useEffect } from 'react';
import logoIcon from '@svg/header_logo.svg';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import ProgressBar from '@components/progress_bar';
import useSignupContext from '@hooks/context/useSignupContext';
import Typography from '@components/common/typography';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '@const/routes';

const Header = () => {
    const { steps, currentStep, availableStep } = useSignupContext();
    const { i18n } = useTranslation(undefined, { keyPrefix: 'header' });
    const location = useLocation();

    const handleChangeLanguage = () => {
        const lang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    return (
        <header className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.mobile_justify} />
                <Link to={routes.dashboard}>
                    <img src={logoIcon} alt="logo" />
                </Link>
                {location.pathname === '/signup' && currentStep?.id !== '1' && (
                    <ProgressBar
                        className={styles.progress_bar}
                        steps={steps}
                        availableStep={availableStep}
                        currentStep={currentStep}
                    />
                )}
                <div className={styles.right}>
                    <Button className={styles.language} onClick={handleChangeLanguage}>
                        <Typography
                            className={i18n.language === 'en' ? styles.isActive : ''}
                            variant="body2"
                        >
                            EN
                        </Typography>
                        |
                        <Typography
                            className={i18n.language === 'fr' ? styles.isActive : ''}
                            variant="body2"
                        >
                            FR
                        </Typography>
                    </Button>
                </div>
            </div>
        </header>
    );
};

export default Header;
