import { TAddress } from '@types';
import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

export interface IProps {
    currentStep: number;
    messages: {
        shippingAddress: TAddress
    }
}

export const requestCardValidation = ({ currentStep, messages }: IProps) => {
    const steps = [
        z.object({}),
        z.object({
            shippingAddress: validator.address(messages.shippingAddress)
        }),
        z.object({}),
    ]
    return steps[currentStep]
}
