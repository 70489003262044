import React from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Card from '@components/cards/card';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';

const CardsSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.cards'
    });
    const { wallet, isDataLoading } = useUserContext();
    const { verifiedAction } = useVerificationGuard();

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.row_wrapper}>
                <div className={styles.row}>
                    {isDataLoading ? (
                        <>
                            <Skeleton className={styles.skeleton} />
                            <Skeleton className={styles.skeleton} />
                        </>
                    ) : (
                        <>
                            {wallet?.cards?.map((item) => <Card key={item.id} card={item} />)}
                            <button
                                className={styles.new_card}
                                onClick={() =>
                                    verifiedAction(() => navigate(routes.order_xeppt_card))
                                }>
                                <div>
                                    <Icon name="plus" />
                                </div>
                                <Typography variant="body1" weight="bold">
                                    {t('new_card')}
                                </Typography>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardsSection;
