import React, { FC, useEffect, useMemo } from 'react';
import Header from './header';
import styles from './styles.module.scss';
import planetImage from '@svg/planet.svg';
import classNames from 'classnames/bind';
import { Outlet, useLocation } from 'react-router-dom';
import ActivityServiceProvider from '../../service_providers/activity_provider';

interface IProps {
    className?: string;
}

const cx = classNames.bind(styles);
const GlobalProvider: FC<IProps> = ({ className }) => {
    const location = useLocation();

    const rootStyles = useMemo(() => cx([styles.wrapper, className]), [className]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <ActivityServiceProvider>
            <div className={rootStyles}>
                <Header />
                <Outlet />
                <div className={styles.planet}>
                    <img src={planetImage} alt="planet illustration" />
                </div>
            </div>
        </ActivityServiceProvider>
    );
};

export default GlobalProvider;
