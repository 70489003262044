import React, { FC } from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    handleNextStep: () => void;
}

const SentInteracRequestStep: FC<IProps> = ({ handleNextStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.sent_interac_request'
    });
    const {account} = useUserContext()
    return (
        <div className={styles.wrapper}>
            <Icon name="interac" width={50} height={50} />
            <Typography variant="h4" className={styles.title}>
                {t('title')}
            </Typography>
            <p className={styles.description}>{`${t('description_1')} ${account?.eTransferAccount?.interacEmail} ${t('description_2')}`}</p>
            <Button
                className={styles.paid}
                size="normal"
                variant="primary"
                onClick={handleNextStep}
            >
                {t('paid')}
            </Button>
            <Button className={styles.later} size="normal" variant="outlined">
                {t('later')}
            </Button>
        </div>
    );
};

export default SentInteracRequestStep;
