import React from 'react';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import welcomeIllustration from '@svg/illustrations/welcome.svg';
import Typography from '@components/common/typography';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';

const CongratsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.congrats'
    });
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <img src={welcomeIllustration} alt="welcome illustration" />
            <Typography variant="body1">{t('title')}</Typography>
            <Button onClick={() => navigate(routes.signin)} size="normal" variant="primary">
                {t('go_to_login')}
            </Button>
        </div>
    );
};

export default CongratsSection;
