import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    handleChoseOtherType: () => void;
    type: 'manual' | 'instant';
    onChange: (value: 'manual' | 'instant') => void;
}

const cx = classNames.bind(styles);

const FirstStepLinkBank: FC<IProps> = ({ handleChoseOtherType, type, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_1'
    });
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <button
                className={cx([styles.card, { isActive: type === 'instant' }])}
                onClick={() => onChange('instant')}
            >
                <div className={styles.left}>
                    <Typography variant="body1" weight="semibold">
                        {t('instant')}
                    </Typography>
                    <span>{t('instant_description')}</span>
                </div>
                <Radio checked={type === 'instant'} onClick={() => onChange('instant')} />
            </button>
            <button
                className={cx([styles.card, { isActive: type === 'manual' }])}
                onClick={() => onChange('manual')}
            >
                <div className={styles.left}>
                    <Typography variant="body1" weight="semibold">
                        {t('manual')}
                    </Typography>
                    <span>{t('manual_description')}</span>
                </div>
                <Radio checked={type === 'manual'} />
            </button>
            {/*<button className={styles.back} onClick={handleChoseOtherType}>*/}
            {/*    <Icon name="arrow_left" />*/}
            {/*    <p>{t('link_card')}</p>*/}
            {/*</button>*/}
        </div>
    );
};

export default FirstStepLinkBank;
