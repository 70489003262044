import { useUserContext } from '@hooks/context/useUserContext';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';

export const useVerificationGuard = () => {
    const { user } = useUserContext();
    const { onOpen } = useModalContext();

    const verifiedAction = <T = void>(callback: () => T) => {
        if (user?.status === EUserStatus.PENDING) {
            onOpen({
                modalId: modalIds.VERIFICATION_PENDING
            });
        } else if (user?.status !== EUserStatus.UNVERIFIED) {
            return callback();
        } else {
            onOpen({
                modalId: modalIds.KYC_FIRST_MODAL
            });
        }
    };

    return {
        verifiedAction
    };
};
