import React, { FC } from 'react';
import Radio from '@components/common/radio';
import { hideCardNumber } from '@utils/index';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    name: string;
    account?: string;
    checked: boolean;
    onChange: () => void;
}

const cx = classNames.bind(styles);

const BankAccountRadio: FC<IProps> = ({ name, checked, onChange, account }) => {
    return (
        <div className={cx([styles.wrapper, { checked }])} onClick={onChange}>
            <div className={styles.left}>
                <div className={styles.content}>
                    <div className={styles.text_wrapper}>
                        <p>{name}</p>
                        {account && (
                            <span>{account}</span>
                        )}
                    </div>
                </div>
            </div>
            <Radio checked={checked} />
        </div>
    );
};

export default BankAccountRadio;
