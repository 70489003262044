import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { Icon } from '@components/icons';
import Radio from '@components/common/radio';
import { useFormContext } from 'react-hook-form';
import { EUserType } from '@xeppt/xeppt-sdk/types/user';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    onChange: (val: EUserType) => void;
    value: EUserType;
}

const AccountTypeSection: FC<IProps> = ({ onChange, value }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.account_type'
    });
    const { submitLocale } = useLocales();
    const { nextStep } = useSignupContext();
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue('type', value);
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <div className={styles.cards_wrapper}>
                    <div className={styles.card} onClick={() => onChange(EUserType.INDIVIDUAL)}>
                        <div className={styles.content}>
                            <Typography className={styles.title} variant="h4" weight="bold">
                                {t('personal')}
                            </Typography>
                            <Typography className={styles.description} variant="body3">
                                {t('personal_1')}
                            </Typography>
                            <div className={styles.check_row}>
                                <Icon width={20} height={20} name="check_outlined" />
                                <p>{t('personal_2')}</p>
                            </div>
                            <div className={styles.check_row}>
                                <Icon width={20} height={20} name="check_outlined" />
                                <p>{t('personal_3')}</p>
                            </div>
                        </div>
                        <Radio checked={value === EUserType.INDIVIDUAL} className={styles.radio} />
                    </div>
                    <div className={styles.card} onClick={() => onChange(EUserType.BUSINESS)}>
                        <div className={styles.content}>
                            <Typography className={styles.title} variant="h4" weight="bold">
                                {t('business')}
                            </Typography>
                            <Typography className={styles.description} variant="body3">
                                {t('business_1')}
                            </Typography>
                            <div className={styles.check_row}>
                                <Icon width={20} height={20} name="check_outlined" />
                                <p>{t('business_2')}</p>
                            </div>
                            <div className={styles.check_row}>
                                <Icon width={20} height={20} name="check_outlined" />
                                <p>{t('business_3')}</p>
                            </div>
                        </div>
                        <Radio checked={value === EUserType.BUSINESS} className={styles.radio} />
                    </div>
                </div>
                <Button onClick={nextStep} size="normal" variant="primary">
                    {submitLocale('next')}
                </Button>
            </div>
        </div>
    );
};

export default AccountTypeSection;
