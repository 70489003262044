import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import CardTypeSection from '@sections/order_card/card_type';
import ProgressBar from '@components/progress_bar';
import Button from '@components/common/button';
import ThirdStep from '@sections/order_card/step3';
import SuccessStep from '@sections/order_card/success';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { useForm } from '@hooks/helpers/useForm';
import { addressValidationErrors, defaultRequestCard } from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { requestCardValidation } from '@helpers/validation_objects/cards';
import { useLocales } from '@hooks/helpers/useLocales';
import { FormProvider } from 'react-hook-form';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiWalletService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { TRequestCardPayload } from '@xeppt/xeppt-sdk/types/card';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';

const steps = (localization: (val: string) => string) => [
    // {
    //     title: localization('occupation'),
    //     id: '1'
    // },
    // {
    //     title: localization('photo_id'),
    //     id: '2'
    // },
    {
        title: localization('choose_card'),
        id: '1'
    },
    {
        title: localization('shipping_address'),
        id: '2'
    }
    // {
    //     title: localization('review'),
    //     id: '3'
    // }
];

const OrderCardLayout = () => {
    const { t } = useTranslation('order_card');
    const { validationLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const navigate = useNavigate();
    const { user, refetchWallet, refetchAccount } = useUserContext();
    const stepsMemo = useMemo(() => steps(t), [t]);
    const { currentStep, availableStep, prevStep, nextStep } = useProgressBar(stepsMemo);
    const { verifiedAction } = useVerificationGuard();
    const [isDone, setIsDone] = useState(false);
    const form = useForm<TRequestCardPayload>({
        defaultValues: defaultRequestCard,
        resolver: zodResolver(
            requestCardValidation({
                currentStep: Number(currentStep?.id || 1) - 1,
                messages: {
                    shippingAddress: addressValidationErrors(validationLocale)
                }
            })
        )
    });

    const { handleRequest: handleRequestCard } = useApiMutation({
        method: () =>
            apiWalletService.requestCard({
                ...form.getValues(),
                profile: {
                    firstName: user?.profile.firstName || '',
                    lastName: user?.profile.lastName || ''
                }
            }),
        onSuccess: () => {
            requestSuccessLocale('request_card');
            refetchWallet();
            refetchAccount();
            nextStep();
            setIsDone(true);
        },
        onError: (e) => {
            setIsDone(false);
            requestErrorLocale(e);
        }
    });

    const renderStep = () => {
        switch (currentStep?.id) {
            // case '1':
            //     return <FirstStep />;
            // case '2':
            //     return <SecondStep />;
            case '1':
                return <CardTypeSection nextStep={() => verifiedAction(nextStep)} />;
            case '2':
                return <ThirdStep />;
            // case '3':
            //     return <FourthStep />;
            default:
                return <SuccessStep />;
        }
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            {!isDone && (
                <div className={styles.title}>
                    {currentStep?.id === '1' ? (
                        <Typography variant="h4">{t('choose_card')}</Typography>
                    ) : (
                        <ProgressBar
                            className={styles.progress_bar}
                            size="large"
                            variant="light"
                            currentStep={currentStep}
                            availableStep={availableStep}
                            steps={steps(t)}
                        />
                    )}
                </div>
            )}
            <FormProvider {...form}>
                <div className={styles.content}>{renderStep()}</div>
            </FormProvider>
            {currentStep?.id !== '1' && (
                <div className={styles.actions_wrapper}>
                    <Button
                        variant="primary"
                        size="normal"
                        disabled={!form.formState.isValid}
                        onClick={() =>
                            verifiedAction(() => {
                                if (!isDone) {
                                    if (currentStep?.id === '2') {
                                        handleRequestCard(undefined);
                                    } else {
                                        nextStep();
                                    }
                                } else {
                                    navigate(routes.xeppt_cards);
                                }
                            })
                        }>
                        {isDone ? t('done') : t('next')}
                    </Button>
                    {!isDone && (
                        <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                            {t('back')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default OrderCardLayout;
