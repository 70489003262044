import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import { Icon } from '@components/icons';
import FormField from '@components/form_field';
import { getCardBrand } from '@utils/payment';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

interface IProps {
    handleChoseOtherType: () => void;
}

const FirstStepLinkCard: FC<IProps> = ({ handleChoseOtherType }) => {
    const { labelLocale } = useLocales();
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_card.step_1'
    });
    return (
        <div className={styles.wrapper}>
            <Typography variant="body2">{t('title')}</Typography>
            <div className={styles.row}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('first_name')}
                            className={styles.input}
                        />
                    )}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('last_name')}
                            className={styles.input}
                        />
                    )}
                />
            </div>
            <FormField
                name="cardNumber"
                renderComponent={(props) => (
                    <Input
                        {...props}
                        label={labelLocale('card_number')}
                        className={styles.input}
                        full
                        mask="9999 9999 9999 9999"
                        //eslint-disable-next-line react/prop-types
                        rightIcon={getCardBrand(props.value)}
                    />
                )}
            />
            <div className={styles.row}>
                <FormField
                    name="expire"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('expiry_date')}
                            className={styles.input}
                            full
                            mask="99/99"
                            unmask={false}
                        />
                    )}
                />
                <FormField
                    name="code"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('security_code')}
                            className={styles.input}
                            full
                            mask="9999"
                        />
                    )}
                />
            </div>
            <button onClick={handleChoseOtherType}>
                <Icon name="arrow_left" />
                <p>{t('link_bank')}</p>
            </button>
        </div>
    );
};

export default FirstStepLinkCard;
