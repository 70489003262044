import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '@const/routes';
import moment from 'moment/moment';
import Status from '@components/common/status';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { currencies } from '@const/currency';
import { getTransactionStatus, prettifyAmount } from '@utils/index';
import { ETransactionStatus } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import Skeleton from '@components/common/skeleton';

const TransactionDetailsLayout = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('transaction_details');
    const { id } = useParams<{ id: string }>();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { refetchWallet, refetchAccount } = useUserContext();

    const {
        data: transaction,
        handleRequest: refetchTransaction,
        isLoading
    } = useApiQuery({
        method: () => apiAccountService.getTransactionById(id as string)
    });
    console.log(isLoading);

    const handleApproveTransaction = (id: string) => {
        apiAccountService
            ._temp_approveTransaction(id)
            .then(() => {
                requestSuccessLocale('approve_transaction');
                refetchTransaction();
                refetchWallet();
                refetchAccount();
            })
            .catch(requestErrorLocale);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.head}>
                <Button onClick={() => navigate(routes.transactions)} leftIcon="arrow_left">
                    {t('summary')}
                </Button>
            </div>
            {isLoading ? (
                <Skeleton className={styles.skeleton} />
            ) : (
                <div className={styles.content_wrapper}>
                    <Typography variant="body1" weight="bold">
                        {t('details')}
                    </Typography>
                    <div className={styles.content}>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_id')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.id}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('amount')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {currencies[transaction?.currency as 'CAD']?.symbol}{' '}
                                {prettifyAmount(transaction?.amount?.toFixed(2) || '0')}{' '}
                                {transaction?.currency}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('transaction_type')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.method &&
                                    enumTranslate[transaction.method][i18n.language as 'en' | 'fr']}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('description')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {transaction?.description}
                            </Typography>
                        </div>
                        <div className={styles.item}>
                            <Typography variant="body3">{t('date')}</Typography>
                            <Typography weight="bold" variant="body3">
                                {moment(transaction?.createdAt).format('DD/MM/YYYY HH:mm')}
                            </Typography>
                        </div>
                        {/*<div className={styles.item}>*/}
                        {/*    <Typography variant="body3">{t('fee')}</Typography>*/}
                        {/*    <Typography weight="bold" variant="body3">*/}
                        {/*        $ 1.00 CAD*/}
                        {/*    </Typography>*/}
                        {/*</div>*/}
                        <div className={styles.item}>
                            <Typography variant="body3">{t('status')}</Typography>
                            <Typography weight="bold" variant="body3">
                                <Status variant={getTransactionStatus(transaction?.status)}>
                                    {transaction?.status &&
                                        ETransactionStatusTranslate[transaction.status][
                                            i18n.language as 'en' | 'fr'
                                        ]}
                                </Status>
                            </Typography>
                        </div>
                        {transaction?.status === ETransactionStatus.PENDING && (
                            <div className={styles.item}>
                                <Typography variant="body3">Approve transaction</Typography>

                                <Button
                                    variant="primary"
                                    size="small"
                                    onClick={() => handleApproveTransaction(transaction?.id)}>
                                    Approve
                                </Button>
                            </div>
                        )}
                    </div>
                    {/*<Button className={styles.button} variant="primary" size="small">*/}
                    {/*    {t('refund')}*/}
                    {/*</Button>*/}
                </div>
            )}
        </div>
    );
};

export default TransactionDetailsLayout;
