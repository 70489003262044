import LinkCardOrBankModal from '../UI/modals/dashboard/link_card_bank';
import NotificationsModal from '../UI/modals/settings/notifications';
import VerificationPhoneModal from '../UI/modals/settings/verification_phone';
import ChangePasswordModal from '../UI/modals/settings/change_password';
import ActivateCardModal from '../UI/modals/xeppt_card/success_activate_card/enter_pin';
import SuccessActivateCardModal from '../UI/modals/xeppt_card/success_activate_card';
import CardDetailsEnterPasswordModal from '../UI/modals/xeppt_card/card_details/enter_password';
import CardDetailsModal from '../UI/modals/xeppt_card/card_details';
import LockCardModal from '../UI/modals/xeppt_card/lock_card';
import UnlockCardModal from '../UI/modals/xeppt_card/unlock_card';
import SuccessUpdatePinModal from '../UI/modals/xeppt_card/update_pin';
import UpdatePinModal from '../UI/modals/xeppt_card/update_pin/enter_pin';
import CardLimitsModal from '../UI/modals/xeppt_card/card_limits';
import ReplaceCardReasonModal from '../UI/modals/xeppt_card/replace_card/reason';
import ReplaceCardSubmitModal from '../UI/modals/xeppt_card/replace_card/submit';
import ReplaceCardSuccessModal from '../UI/modals/xeppt_card/replace_card';
import CancelCardReasonModal from '../UI/modals/xeppt_card/cancel_card/reason';
import CancelCardSubmitModal from '../UI/modals/xeppt_card/cancel_card/submit';
import CancelCardSuccessModal from '../UI/modals/xeppt_card/cancel_card';
import ManageCardModal from '../UI/modals/xeppt_card/manage_card';
import VerificationEmailModal from '../UI/modals/settings/verification_email';
import AddPhoneModal from '../UI/modals/settings/add_phone';
import AddEmailModal from '../UI/modals/settings/add_email';
import DeleteEmailModal from '../UI/modals/settings/delete_email';
import LoadCardBalanceModal from '../UI/modals/dashboard/load_card_balance';
import LinkInteracModal from '../UI/modals/dashboard/link_interac';
import VerificationPhoneWalletModal from '../UI/modals/settings/verification_phone_wallet';
import VerificationPhoneUserModal from '../UI/modals/settings/verification_phone_profile';
import UploadAvatarModal from '../UI/modals/settings/upload_avatar';
import UserActivityModal from '../UI/modals/global/user_activity';
import React from 'react';
import { IModalComponentProps } from '@context/modal_context';
import KycModal from '../UI/modals/global/kyc';
import WaitVerificationModal from '../UI/modals/global/wait_verification';

type ModalType = {
    modalId: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    variant: 'medium' | 'small' | 'custom' | 'large';
    Component: React.FC<IModalComponentProps> | null;
};

const modalIds = {
    LINK_BANK_OR_CARD: 'link xeppt_cards or bank',
    SETTINGS_NOTIFICATION: 'settings notification',
    SETTINGS_VERIFICATION_PHONE: 'settings verification phone',
    PIN_VERIFICATION_PHONE: 'pin verification phone',
    USER_VERIFICATION_PHONE: 'user verification phone',
    SETTINGS_VERIFICATION_EMAIL: 'settings verification email',
    SETTINGS_ADD_PHONE_MODAL: 'add phone modal',
    SETTINGS_ADD_EMAIL_MODAL: 'add email modal',
    SETTINGS_CHANGE_PASSWORD: 'settings change password',
    SETTINGS_UPLOAD_IMAGE: 'settings upload image',
    ACTIVATE_CARD: 'activate card',
    SUCCESS_ACTIVATE_CARD: 'success activate card',
    CARD_DETAILS_ENTER_PASSWORD: 'card details enter password',
    CARD_DETAILS: 'card details',
    LOCK_CARD: 'lock card',
    UNLOCK_CARD: 'unlock card',
    UPDATE_PIN: 'update pin modal',
    SUCCESS_UPDATE_PIN: 'success update pin modal',
    CARD_LIMITS: 'card limits',
    REPLACE_CARD_REASON: 'replace card reason',
    REPLACE_CARD_SUBMIT: 'replace card submit',
    REPLACE_CARD_SUCCESS: 'replace card success',
    CANCEL_CARD_REASON: 'cancel card reason',
    CANCEL_CARD_SUBMIT: 'cancel card submit',
    CANCEL_CARD_SUCCESS: 'cancel card success',
    MANAGE_CREDIT_CARD: 'manage credit card',
    DELETE_EMAIL: 'delete email',
    LOAD_CARD_BALANCE: 'load card balance',
    LINK_INTERAC_EMAIL: 'link interac email',
    USER_ACTIVITY_MODAL: 'user activity modal',
    KYC_FIRST_MODAL: 'kyc first modal',
    VERIFICATION_PENDING: 'verification pending',
    DELETE_PHONE: 'delete phone'
};

const linkBankOrCardModal: ModalType = {
    modalId: modalIds.LINK_BANK_OR_CARD,
    variant: 'small',
    Component: LinkCardOrBankModal
};

const settingsNotificationsModal: ModalType = {
    modalId: modalIds.SETTINGS_NOTIFICATION,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: NotificationsModal
};

const settingsVerificationPhoneModal: ModalType = {
    modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneModal
};

const settingsVerificationEmailModal: ModalType = {
    modalId: modalIds.SETTINGS_VERIFICATION_EMAIL,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationEmailModal
};

const settingsAddPhoneModal: ModalType = {
    modalId: modalIds.SETTINGS_ADD_PHONE_MODAL,
    confirmLabel: 'verify',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: AddPhoneModal
};

const settingsAddEmailModal: ModalType = {
    modalId: modalIds.SETTINGS_ADD_EMAIL_MODAL,
    confirmLabel: 'verify',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: AddEmailModal
};

const settingsChangePasswordModal: ModalType = {
    modalId: modalIds.SETTINGS_CHANGE_PASSWORD,
    confirmLabel: 'save',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ChangePasswordModal
};

const activateCardModal: ModalType = {
    modalId: modalIds.ACTIVATE_CARD,
    confirmLabel: 'confirm',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ActivateCardModal
};

const successActivateCardModal: ModalType = {
    modalId: modalIds.SUCCESS_ACTIVATE_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: SuccessActivateCardModal
};

const cardDetailsEnterPasswordModal: ModalType = {
    modalId: modalIds.CARD_DETAILS_ENTER_PASSWORD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardDetailsEnterPasswordModal
};

const walletPhoneVerification: ModalType = {
    modalId: modalIds.PIN_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneWalletModal
};
const userPhoneVerification: ModalType = {
    modalId: modalIds.USER_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneUserModal
};

const cardDetailsModal: ModalType = {
    modalId: modalIds.CARD_DETAILS,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardDetailsModal
};

const lockCardModal: ModalType = {
    modalId: modalIds.LOCK_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: LockCardModal
};

const unlockCardModal: ModalType = {
    modalId: modalIds.UNLOCK_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: UnlockCardModal
};

const updatePinModal: ModalType = {
    modalId: modalIds.UPDATE_PIN,
    confirmLabel: 'update',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: UpdatePinModal
};

const successUpdatePinModal: ModalType = {
    modalId: modalIds.SUCCESS_UPDATE_PIN,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: SuccessUpdatePinModal
};

const cardLimitsModal: ModalType = {
    modalId: modalIds.CARD_LIMITS,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardLimitsModal
};

const replaceCardReasonModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_REASON,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardReasonModal
};

const replaceCardSubmitModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_SUBMIT,
    confirmLabel: 'yes',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardSubmitModal
};

const replaceCardSuccessModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_SUCCESS,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardSuccessModal
};

const cancelCardReasonModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_REASON,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardReasonModal
};

const cancelCardSubmitModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_SUBMIT,
    confirmLabel: 'yes',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardSubmitModal
};

const manageCreditCardModal: ModalType = {
    modalId: modalIds.MANAGE_CREDIT_CARD,
    isHeader: true,
    variant: 'large',
    Component: ManageCardModal
};

const cancelCardSuccessModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_SUCCESS,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardSuccessModal
};

const deleteEmail: ModalType = {
    modalId: modalIds.DELETE_EMAIL,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: DeleteEmailModal
};

const deletePhone: ModalType = {
    modalId: modalIds.DELETE_PHONE,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: DeleteEmailModal
};

const loadCardBalanceModal: ModalType = {
    modalId: modalIds.LOAD_CARD_BALANCE,
    confirmLabel: 'add_money',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: LoadCardBalanceModal
};

const linkInteracEmail: ModalType = {
    modalId: modalIds.LINK_INTERAC_EMAIL,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: LinkInteracModal
};

const uploadImageModal: ModalType = {
    modalId: modalIds.SETTINGS_UPLOAD_IMAGE,
    isHeader: true,
    variant: 'medium',
    Component: UploadAvatarModal
};

const userActivityModal: ModalType = {
    modalId: modalIds.USER_ACTIVITY_MODAL,
    isHeader: true,
    variant: 'small',
    Component: UserActivityModal
};

const kycFirstModal: ModalType = {
    modalId: modalIds.KYC_FIRST_MODAL,
    isHeader: true,
    variant: 'custom',
    Component: KycModal
};

const verificationPendingModal: ModalType = {
    modalId: modalIds.VERIFICATION_PENDING,
    isHeader: true,
    variant: 'small',
    Component: WaitVerificationModal
};

const modals: ModalType[] = [
    linkInteracEmail,
    loadCardBalanceModal,
    linkBankOrCardModal,
    settingsNotificationsModal,
    settingsVerificationPhoneModal,
    settingsChangePasswordModal,
    activateCardModal,
    successActivateCardModal,
    cardDetailsEnterPasswordModal,
    walletPhoneVerification,
    settingsVerificationEmailModal,
    cardDetailsModal,
    lockCardModal,
    unlockCardModal,
    updatePinModal,
    successUpdatePinModal,
    cardLimitsModal,
    replaceCardReasonModal,
    replaceCardSubmitModal,
    replaceCardSuccessModal,
    cancelCardReasonModal,
    cancelCardSubmitModal,
    cancelCardSuccessModal,
    manageCreditCardModal,
    settingsAddPhoneModal,
    settingsAddEmailModal,
    deleteEmail,
    deletePhone,
    userPhoneVerification,
    uploadImageModal,
    kycFirstModal,
    verificationPendingModal,
    userActivityModal
];

export { modals, modalIds };
