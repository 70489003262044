import { ETransactionMethod, ETransactionStatus } from '@xeppt/xeppt-sdk/types/transaction';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';

export const enumTranslate = {
    [ETransactionMethod.CARD]: {
        en: 'Payment',
        fr: 'Paiement'
    },
    [ETransactionMethod.E_TRANSFER]: {
        en: 'Interac E-Transfer',
        fr: 'Interac E-Transfer'
    },
    [ETransactionMethod.WALLET]: {
        en: 'Wallet',
        fr: 'portefeuille'
    },
    [ETransactionMethod.EFT]: {
        en: 'Transfer from bank',
        fr: 'Virement depuis la banque'
    }
};

export const ETransactionStatusTranslate = {
    [ETransactionStatus.PENDING]: {
        en: 'Pending',
        fr: 'En attente'
    },
    [ETransactionStatus.APPROVED]: {
        en: 'Approved',
        fr: 'Approuvée'
    },
    [ETransactionStatus.DECLINED]: {
        en: 'Declined',
        fr: 'Refusé'
    },
    [ETransactionStatus.CANCELED]: {
        en: 'Canceled',
        fr: 'Annulé'
    }
};

export const ECardStatusTranslation = {
    [ECardStatus.FRAUD]: {
        en: 'Fraud',
        fr: 'Fraude'
    },
    [ECardStatus.STOLEN]: {
        en: 'Stolen',
        fr: 'Volé'
    },
    [ECardStatus.OTHER]: {
        en: 'Other',
        fr: 'Autre'
    },
    [ECardStatus.LOST]: {
        en: 'Lost',
        fr: 'Perdu'
    },
    [ECardStatus.CUSTOMER_REQUEST]: {
        en: 'Customer request',
        fr: 'Demande du client'
    }
};
