import React from 'react';
import Typography from '@components/common/typography';
import Link from '@components/common/link';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInValidation } from '@helpers/validation_objects/signin';
import { defaultSignInData } from '@const/default_form_data';
import { useNavigate } from 'react-router-dom';
import { apiAuthService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

const SignInLayout = () => {
    const { t } = useTranslation('sign_in');
    const { validationLocale, labelLocale, requestErrorLocale, submitLocale } = useLocales();
    const navigate = useNavigate();
    const {
        control,
        formState: { isValid },
        getValues
    } = useForm({
        defaultValues: defaultSignInData,
        resolver: zodResolver(
            signInValidation({
                messages: {
                    email: validationLocale('email')
                }
            })
        )
    });

    const handleSubmitForm = () => {
        const { email, password } = getValues();
        apiAuthService
            .login({
                emailOrPhone: email,
                password
            })
            .then(() => {
                navigate(routes.dashboard);
            })
            .catch(requestErrorLocale);
    };

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('new')} <Link to={routes.signup}>{t('signup')}</Link>
            </Typography>
            <div className={styles.form}>
                <FormField
                    control={control}
                    name="email"
                    renderComponent={(props) => (
                        <Input {...props} full label={labelLocale('email')} name="email" />
                    )}
                />
                <FormField
                    control={control}
                    name="password"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            type="password"
                            name="password"
                            label={labelLocale('password')}
                        />
                    )}
                />
                <Link to={routes.forgot_password} className={styles.forgot}>
                    {t('forgot')}
                </Link>
            </div>
            <Button onClick={handleSubmitForm} disabled={!isValid} variant="primary" size="normal">
                {submitLocale('login')}
            </Button>
        </div>
    );
};
export default SignInLayout;
