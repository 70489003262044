import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import { Icon } from '@components/icons';

interface IProps {
    handleChooseOtherMethod: () => void;
}

const SecondStepInstantLinkBank: FC<IProps> = ({ handleChooseOtherMethod }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_2_instant'
    });
    const { user } = useUserContext();

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <Typography variant="body1">{t('description')}</Typography>
            <iframe
                className="flinksconnect"
                height="760"
                style={{ border: 'none' }}
                src={`${process.env.REACT_APP_FLINKS_URL}?demo=true&headerImgEnable=false&accountSelectorEnable=true&accountSelectorCurrency=cad&consentEnable=false&redirectUrl=${window.location.origin}/link-xeppt_cards-or-bank-account&user=${user?.id}`}></iframe>
            <button className={styles.back} onClick={handleChooseOtherMethod}>
                <Icon name="arrow_left" />
                <p>{t('other_method')}</p>
            </button>
        </div>
    );
};

export default SecondStepInstantLinkBank;
