import React, { useState } from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import RangeDatePicker from '@components/range_date_picker';
import { TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import TransactionTable from '@components/transactions_table';

const TransactionsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_card.transaction'
    });

    const [filter, setFilter] = useState<TTransactionsFilter>({
        dateFrom: undefined,
        dateTo: undefined
    });
    const { data: transactions, isLoading } = useApiQuery({
        method: () => apiAccountService.findTransactions(filter, undefined, 10),
        deps: [filter]
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.range_date_wrapper}>
                    <RangeDatePicker
                        values={[filter.dateFrom?.toString(), filter.dateTo?.toString()]}
                        onChange={(val) => {
                            setFilter({
                                dateFrom: val?.[0] ? new Date(val?.[0]) : undefined,
                                dateTo: val?.[1] ? new Date(val?.[1]) : undefined
                            });
                        }}
                        full
                    />
                </div>
            </div>
            <div className={styles.content}>
                <TransactionTable
                    isTransactionsLoading={isLoading}
                    transactions={transactions?.data || []}
                />
            </div>
        </div>
    );
};

export default TransactionsSection;
