export const errorCodes = [
    "EMAIL_NOT_FOUND",
    "PHONE_NOT_FOUND",
    "AUTH_INVALID_CREDENTIALS",
    "USER_ALREADY_ACTIVATED",
    "INVALID_RESET_TOKEN",
    "EMAIL_EXISTS",
    "PHONE_EXISTS",
    "EMAIL_IS_PRIMARY",
    "PHONE_IS_PRIMARY",
    "EMAIL_NOT_VERIFIED",
    "PHONE_NOT_VERIFIED",
    "INVALID_VERIFICATION_CODE",
    "USER_NOT_FOUND",
    "USER_ALREADY_ACTIVATE",
    "INVALID_STRING_FORMAT",
    "MUST_BE_A_NUMBER",
    "INVALID_ISO_CODE",
    "INVALID_DATE_FORMAT",
    "INVALID_URL_FORMAT",
    "LENGTH",
    "MIN_LENGTH",
    "MAX_LENGTH",
    "MUST_BE_EQUAL",
    "MUST_BE_ONE_OF",
    "INVALID_REQUEST",
    "NOT_FOUND",
    "INVALID_ACTIVATION_CODE",
]
