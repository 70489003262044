import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';

interface IProps {
    onClick?: () => void;
    checked?: boolean;
    fees: number;
    type: ETransactionMethod;
}

const cx = classNames.bind(styles);
const CardChoose: FC<IProps> = ({ onClick, type, fees, checked }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'card_choose'
    });
    const rootStyles = useMemo(() => cx([styles.wrapper, { isChecked: checked }]), [checked]);

    const renderSendMessage = () => {
        switch (type) {
            case ETransactionMethod.CARD:
                return t('send_card');
            case ETransactionMethod.EFT:
                return t('send_bank');
            case ETransactionMethod.E_TRANSFER:
                return t('send_e_transfer');
        }
    };

    const renderArriveMessage = () => {
        switch (type) {
            case ETransactionMethod.CARD:
                return t('arrive_card');
            case ETransactionMethod.EFT:
                return t('arrive_bank');
            case ETransactionMethod.E_TRANSFER:
                return t('arrive_e_transfer');
        }
    };

    const renderTitle = () => {
        switch (type) {
            case ETransactionMethod.CARD:
                return t('card_title');
            case ETransactionMethod.EFT:
                return t('bank_title');
            case ETransactionMethod.E_TRANSFER:
                return t('e_transfer_title');
        }
    };

    const getIcon = () => {
        switch (type) {
            case ETransactionMethod.CARD:
                return 'credit_card';
            case ETransactionMethod.EFT:
                return 'bank';
            case ETransactionMethod.E_TRANSFER:
                return 'interac';
            default:
                return 'credit_card'
        }
    };

    return (
        <button
            className={rootStyles}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <div className={styles.left}>
                <div className={styles.icon}>
                    <Icon name={getIcon()} />
                </div>
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="semibold">
                        {renderTitle()}
                    </Typography>
                    <Typography variant="body3">
                        {fees} {t('total_fee')} <br />
                        {renderSendMessage()} <br />
                        {renderArriveMessage()}
                    </Typography>
                </div>
            </div>
            <Radio checked={checked} />
        </button>
    );
};

export default CardChoose;
