import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import Link from '@components/common/link';
import VerificationCode from '@components/verification_code';
import { routes } from '@const/routes';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';
import { TRegisterIndividualUser } from '@xeppt/xeppt-sdk/types/registration';
import { TNewPhone, TVerifyPhone } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

interface IProps {
    onBack: () => void;
}

const VerificationPhoneSection: FC<IProps> = ({ onBack }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.verification_phone'
    });
    const { submitLocale } = useLocales();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const {
        formState: { isValid },
        getValues
    } = useFormContext<TRegisterIndividualUser>();

    const { handleRequest: handleVerify } = useApiMutation({
        method: (data: TVerifyPhone) => apiRegistrationService.verifyPhone(data),
        onSuccess: () => {
            nextStep();
            requestSuccessLocale('verify_phone');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleSentValidationCode } = useApiMutation({
        method: (data: TNewPhone) => apiRegistrationService.sendPhoneValidationCode(data),
        onSuccess: () => {
            requestSuccessLocale('send_security_code_phone');
        }
    });

    const onResend = () => {
        const { phoneCode, phoneNumber } = getValues();
        handleSentValidationCode({
            phoneCode,
            phoneNumber
        });
    };

    const onSubmit = () => {
        const { phoneNumber, phoneCode, verificationCode } = getValues();
        handleVerify({
            phoneNumber,
            phoneCode,
            code: verificationCode as string
        });
    };

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('code_sent')} <span>+1 647-222-3333</span>{' '}
                <Link to={routes.signin}>{submitLocale('change')}</Link>
            </Typography>
            <div className={styles.form}>
                <Typography variant="body2" fw={600}>
                    {t('enter_code')}
                </Typography>
                <Button onClick={onResend} className={styles.resend}>
                    {submitLocale('resend')}
                </Button>
                <FormField
                    name="verificationCode"
                    renderComponent={(props) => <VerificationCode {...props} />}
                />
                <Button
                    disabled={!isValid}
                    className={styles.next}
                    onClick={onSubmit}
                    size="normal"
                    variant="primary">
                    {submitLocale('verify')}
                </Button>
                <Button
                    className={styles.back}
                    leftIcon="arrow_left"
                    onClick={() => {
                        prevStep();
                        onBack();
                    }}>
                    {submitLocale('back')}
                </Button>
            </div>
        </div>
    );
};

export default VerificationPhoneSection;
