import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    nextStep: () => void;
}

const physicalAmount = 7

const CardTypeSection: FC<IProps> = ({ nextStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'order_card.card_type'
    });
    const {wallet} = useUserContext()
    const {submitLocale} = useLocales()
    const navigate = useNavigate()

    const isEnoughAmount = useMemo(() => {
        return (wallet?.balance || 0) < physicalAmount
    }, [wallet, physicalAmount])

    return (
        <div className={styles.wrapper}>
            {/*<FormField*/}
            {/*    name="type"*/}
            {/*    renderComponent={({ onChange }) => {*/}
            {/*        return (*/}
            {/*            <div className={styles.card}>*/}
            {/*                <Typography className={styles.title} variant="h4">*/}
            {/*                    {t('virtual_card')}*/}
            {/*                </Typography>*/}
            {/*                <Typography className={styles.description} variant="body3">*/}
            {/*                    {t('virtual_description')}*/}
            {/*                </Typography>*/}
            {/*                <div className={styles.shipping}>*/}
            {/*                    <Icon name="send" width={20} height={20} />*/}
            {/*                    {t('virtual_get')}*/}
            {/*                </div>*/}
            {/*                <div className={styles.tag}>{t('virtual_free')}</div>*/}
            {/*                <Button*/}
            {/*                    variant="primary"*/}
            {/*                    size="normal"*/}
            {/*                    disabled*/}
            {/*                    onClick={() => {*/}
            {/*                        onChange(ECardType.VIRTUAL as unknown as string)*/}
            {/*                        nextStep()*/}
            {/*                    }}>*/}
            {/*                    {t('virtual_now')}*/}
            {/*                </Button>*/}
            {/*            </div>*/}
            {/*        );*/}
            {/*    }}*/}
            {/*/>*/}
            <FormField
                name="type"
                renderComponent={({ onChange }) => {
                    return (
                        <div className={styles.card}>
                            <Typography className={styles.title} variant="h4">
                                {t('physical_card')}
                            </Typography>
                            <Typography className={styles.description} variant="body3">
                                {t('physical_description')}
                            </Typography>
                            <div className={styles.shipping}>
                                <Icon name="send" width={20} height={20} />
                                {t('physical_get')}
                            </div>
                            <div className={styles.tag}>${physicalAmount.toFixed(2)}</div>
                            <Button
                                variant="primary"
                                size="normal"
                                onClick={() => {
                                    if (isEnoughAmount) {
                                        navigate(routes.add_money_balance)
                                    } else {
                                        onChange(ECardType.PHYSICAL as unknown as string);
                                        nextStep()
                                    }
                                }}>
                                {isEnoughAmount ? submitLocale('add_money') : t('physical_now')}
                            </Button>
                            {isEnoughAmount && <Typography variant="body3" className={styles.helper_text}>
                                {t('not_enough_balance')}
                            </Typography>}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default CardTypeSection;
