import React, { useState } from 'react';
import styles from './styles.module.scss';
import TableSection from '@sections/transactions/table';
import classNames from 'classnames/bind';
import FilterSection from '@sections/transactions/filter';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';

const cx = classNames.bind(styles);

const TransactionsLayout = () => {
    const [filter, setFilter] = useState<TTransactionsFilter>({
        action: undefined,
        dateFrom: undefined,
        dateTo: undefined
    });
    const { data: transactions, isLoading: isTransactionsLoading } = useApiQuery({
        method: () =>
            apiAccountService.findTransactions(
                {
                    ...filter,
                    action: filter.action?.length === 0 ? undefined : filter.action
                },
                undefined,
                100
            ),
        deps: [filter]
    });
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={cx([styles.left, { isOpen }])}>
                    <TableSection
                        transactions={transactions?.data || []}
                        toggleFilter={() => setIsOpen((state) => !state)}
                        isTransactionsLoading={isTransactionsLoading}
                    />
                </div>
                <div className={cx([styles.right, { isOpen }])}>
                    <FilterSection
                        toggleFilter={() => setIsOpen((state) => !state)}
                        onChangeFilter={(value) => setFilter(value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionsLayout;
