import React, { FC } from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import TransactionTable from '@components/transactions_table';
import { useLocales } from '@hooks/helpers/useLocales';
import { TTransactionLite } from '@xeppt/xeppt-sdk/types/transaction';
import { downloadFileFromBlob, formatDataToXlsx, getTransactionAction } from '@utils/index';
import moment from 'moment/moment';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';

interface IProps {
    transactions: TTransactionLite[];
    isTransactionsLoading?: boolean;
    toggleFilter: () => void;
}

const TableSection: FC<IProps> = ({ transactions, toggleFilter, isTransactionsLoading }) => {
    const { t, i18n } = useTranslation('sections', {
        keyPrefix: 'transactions.table'
    });
    const { t: transactionItemLocale } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });
    const { submitLocale } = useLocales();

    const handleDownloadTransaction = () => {
        const formatTransaction = transactions.map((item) => ({
            'Transaction ID': item.id,
            'First name': item.firstName,
            'Last name': item.lastName,
            Action: getTransactionAction(item.action, transactionItemLocale),
            Amount: item.amount,
            Currency: item.currency,
            'Date ': moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss'),
            Method: enumTranslate[item.method][i18n.language as 'en' | 'fr'],
            Status: ETransactionStatusTranslate[item.status][i18n.language as 'en' | 'fr']
        }));
        downloadFileFromBlob(formatDataToXlsx(formatTransaction), 'all-transactions.csv');
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.table_header}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.right}>
                    <Button
                        variant="outlined-dark"
                        size="medium"
                        leftIcon="filter"
                        onClick={toggleFilter}>
                        <p>{submitLocale('filter')}</p>
                    </Button>
                    <Button
                        variant="primary"
                        size="medium"
                        leftIcon="download"
                        onClick={handleDownloadTransaction}>
                        <p>{submitLocale('download')}</p>
                    </Button>
                </div>
            </div>
            <div className={styles.content}>
                <TransactionTable
                    isTransactionsLoading={isTransactionsLoading}
                    transactions={transactions}
                />
            </div>
        </div>
    );
};

export default TableSection;
