import React, { createContext, useEffect, useMemo, useState } from 'react';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { apiAccountService, apiAuthService, apiUserService, apiWalletService } from '@api';
import { routes } from '@const/routes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isBusinessUserTypeGuard, TBusinessDetails } from '@types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { TAccount, TWallet } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';

type TUserContext = {
    user?: TIndividualProfile | TBusinessProfile;
    wallet?: TWallet;
    account?: TAccount;
    businessData?: TBusinessDetails;
    handleLogout: () => void;
    refetchWallet: () => void;
    refetchAccount: () => void;
    refetchUser: () => void;
    isDataLoading: boolean;
};

const initialState: TUserContext = {
    user: undefined,
    wallet: undefined,
    account: undefined,
    businessData: undefined,
    handleLogout: () => Promise.resolve(),
    refetchWallet: () => Promise.resolve(),
    refetchAccount: () => Promise.resolve(),
    refetchUser: () => Promise.resolve(),
    isDataLoading: true
};

const authRoutes = [routes.signin, routes.forgot_password, routes.signup];

export const UserContext = createContext<TUserContext>(initialState);

export const UserContextProvider = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [businessData, setBusinessData] = useState<TBusinessDetails>();
    const [user, setUser] = useState<TBusinessProfile | TIndividualProfile>();
    const [isAuth, setIsAuth] = useState(true);
    const { requestErrorLocale } = useLocales();

    const { isLoading: isUserLoading, handleRequest: refetchUser } = useApiQuery({
        method: () => apiUserService.profile(),
        condition: !isAuth,
        deps: [isAuth],
        onSuccess: (data) => {
            setUser(data);
            if (isBusinessUserTypeGuard(data)) {
                if (data.business) {
                    const { business } = data;
                    setBusinessData(business);
                }
            }
        },
        onError: () => {
            navigate(routes.signin);
        }
    });

    const {
        data: wallet,
        handleRequest: refetchWallet,
        isLoading: isWalletLoading
    } = useApiQuery({
        method: () => apiWalletService.getWallet(),
        condition: !isAuth,
        deps: [isAuth]
    });

    const {
        data: account,
        handleRequest: refetchAccount,
        isLoading: isAccountLoading
    } = useApiQuery({
        method: () => apiAccountService.getAccount(),
        condition: !isAuth,
        deps: [isAuth]
    });

    useEffect(() => {
        if (authRoutes.includes(location.pathname)) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    }, [location]);

    const handleLogout = () => {
        apiAuthService
            .logout()
            .then(() => navigate(routes.signin))
            .catch(requestErrorLocale);
    };

    const isDataLoading = useMemo(() => {
        return isUserLoading || isAccountLoading || isWalletLoading;
    }, [isUserLoading, isAccountLoading, isWalletLoading]);

    return (
        <UserContext.Provider
            value={{
                user,
                wallet,
                account,
                businessData,
                handleLogout,
                refetchWallet,
                refetchAccount,
                refetchUser,
                isDataLoading
            }}>
            <Outlet />
        </UserContext.Provider>
    );
};
