import React, { FC } from 'react';
import { Icon } from '@components/icons';

import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Typography from '@components/common/typography';

export type TProgressItem = {
    id: string;
    title: string;
};

interface IProps {
    steps: TProgressItem[];
    availableStep: TProgressItem | undefined;
    currentStep: TProgressItem | undefined;
    className?: string;
    isMini?: boolean;
    variant?: 'dark' | 'light';
    size?: 'small' | 'large';
}

const cx = classNames.bind(styles);

const ProgressBar: FC<IProps> = ({
    variant = 'dark',
    isMini = false,
    size = 'small',
    className,
    steps,
    currentStep,
    availableStep
}) => {
    return (
        <>
            {!isMini ? (
                <div className={cx([styles.wrapper, variant, size, className])}>
                    <span
                        className={styles.line}
                        style={{
                            width: `calc(100% - (100% / ${steps.length}))`,
                            left: `calc((100% / ${steps.length}) / 2)`
                        }}
                    />
                    {steps.map((item, i) => (
                        <div
                            key={item.id}
                            className={cx([
                                styles.step,
                                {
                                    isCurrentStep:
                                        i ===
                                        steps.findIndex((item) => item.id === currentStep?.id),
                                    isDoneStep:
                                        i <
                                            steps.findIndex(
                                                (item) => item.id === currentStep?.id
                                            ) || !currentStep
                                }
                            ])}
                        >
                            <div className={styles.point}>
                                {(i < steps.findIndex((item) => item.id === currentStep?.id) ||
                                    !currentStep) && <Icon name="check" width={20} height={20} />}
                                {i === steps.findIndex((item) => item.id === availableStep?.id) && (
                                    <i />
                                )}
                                {i === steps.findIndex((item) => item.id === currentStep?.id) && (
                                    <i />
                                )}
                            </div>
                            <Typography variant="body3">{item.title}</Typography>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={cx([styles.mini_progress, className])}>
                    {steps.map((item, i) => (
                        <span
                            style={{ width: `${100 / steps.length}%` }}
                            className={cx([
                                {
                                    isDone:
                                        i <
                                            steps.findIndex(
                                                (item) => item.id === currentStep?.id
                                            ) || !currentStep
                                }
                            ])}
                            key={item.id}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default ProgressBar;
