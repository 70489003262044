import React from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext } from 'react-hook-form';
import FullAddress from '@components/address';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { TRegisterIndividualUser } from '@xeppt/xeppt-sdk/types/registration';
import { apiRegistrationService } from '@api';

const BillingSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.billing'
    });
    const { submitLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const {
        getValues,
        formState: { isValid }
    } = useFormContext<TRegisterIndividualUser>();

    const onSubmit = () => {
        const values = getValues();
        apiRegistrationService
            .register({
                ...values,
                phoneCode: values.phoneCode
            })
            .then(({ id }) => {
                apiRegistrationService
                    .sendActivationCode(id)
                    .then(() => {
                        requestSuccessLocale('registration');
                        nextStep();
                    })
                    .catch(requestErrorLocale);
            })
            .catch(requestErrorLocale);
    };

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FullAddress name="billingAddress" />
                <Button
                    disabled={!isValid}
                    className={styles.next}
                    onClick={onSubmit}
                    size="normal"
                    variant="primary">
                    Complete
                </Button>
                <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                    {submitLocale('back')}
                </Button>
            </div>
        </div>
    );
};

export default BillingSection;
