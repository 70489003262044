import canadian from '@svg/flags/canadian.svg';

export const currencies = {
    canadian: {
        icon: canadian,
        symbol: '$'
    },
    CAD: {
        icon: canadian,
        symbol: '$'
    }
};
