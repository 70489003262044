import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useFormContext, useWatch } from 'react-hook-form';

interface IProps {
    handleNext: () => void;
    showAction?: boolean;
    currentStep?: string;
}

const SummarySection: FC<IProps> = ({ handleNext, currentStep, showAction = true }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.summary'
    });
    const { submitLocale } = useLocales();
    const { control } = useFormContext();
    const methodId = useWatch({ name: 'id', control });
    const isDisabled = useMemo(() => {
        return currentStep === '3' && !methodId;
    }, [currentStep, methodId]);
    const amount = useWatch({ name: 'amount', control });

    return (
        <div className={styles.wrapper}>
            <Typography variant="body1" weight="semibold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <div className={styles.row}>
                    <p>{t('get')}</p>
                    <span>{Number(amount).toFixed(2)} CAD</span>
                </div>
                {/*<div className={styles.row}>*/}
                {/*    <p>{t('fees')}</p>*/}
                {/*    <span>0.79 CAD</span>*/}
                {/*</div>*/}
                <div className={styles.row}>
                    <p>{t('pay')}</p>
                    <span>{Number(amount).toFixed(2)} CAD</span>
                </div>
                <div className={styles.row}>
                    <p>{t('should')}</p>
                    <span>in seconds</span>
                </div>
            </div>
            {showAction && (
                <Button disabled={isDisabled} variant="primary" size="normal" onClick={handleNext}>
                    {submitLocale('continue')}
                </Button>
            )}
        </div>
    );
};

export default SummarySection;
