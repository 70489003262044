import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import GlobalProvider from './UI/layout_providers/global_provider';
import AuthProvider from './UI/layout_providers/auth_provider';
import SignUpLayout from '@layouts/signup';
import SignInLayout from '@layouts/signin';
import TransactionsLayout from '@layouts/transactions';
import SettingsLayout from '@layouts/settings';
import ProfileLayout from '@layouts/settings/profile';
import ModalContextProvider from './context/modal_context';
import LinkCardBankLayout from '@layouts/link_card_bank';
import { routes } from '@const/routes';
import XepptCardsLayout from '@layouts/xeppt_cards';
import DashboardLayout from '@layouts/dashboard';
import XepptCardLayout from '@layouts/xeppt_cards/card';
import OrderCardLayout from '@layouts/xeppt_cards/order_card';
import AddMoneyLayout from '@layouts/xeppt_cards/add_money';
import NotificationsLayout from '@layouts/notifications';
import TransactionDetailsLayout from '@layouts/transactions/transacrtion_details';
import BusinessLayout from '@layouts/settings/business';
import ForgotPasswordLayout from '@layouts/forgot_password';
import { UserContextProvider } from '@context/user_context';

const authRoutes = [
    { path: routes.signup, element: <SignUpLayout /> },
    { path: routes.signin, element: <SignInLayout /> },
    { path: routes.forgot_password, element: <ForgotPasswordLayout /> }
];

const mainRoutes = [
    { path: routes.dashboard, element: <DashboardLayout /> },
    { path: routes.transactions, element: <TransactionsLayout /> },
    { path: routes.transaction_details, element: <TransactionDetailsLayout /> },
    { path: routes.settings, element: <SettingsLayout /> },
    { path: routes.settings_profile, element: <ProfileLayout /> },
    { path: routes.settings_business, element: <BusinessLayout /> },
    { path: routes.link_card_bank, element: <LinkCardBankLayout /> },
    { path: routes.xeppt_cards, element: <XepptCardsLayout /> },
    { path: routes.xeppt_card, element: <XepptCardLayout /> },
    { path: routes.order_xeppt_card, element: <OrderCardLayout /> },
    { path: routes.add_money_balance, element: <AddMoneyLayout /> },
    { path: routes.notifications, element: <NotificationsLayout /> }
];

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UserContextProvider />}>
            <Route element={<ModalContextProvider />}>
                <Route element={<AuthProvider />}>
                    {authRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Route>
                <Route element={<GlobalProvider />}>
                    {mainRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Route>
            </Route>
        </Route>
    )
);
