import React, { FC } from 'react';
import Input from '@components/common/input';
import Select from '@components/phone/select';
import styles from './styles.module.scss';
import FormField from '@components/form_field';

interface IProps {
    label?: string;
    disabled?: boolean;
    readOnly?: boolean;
    section?: string;
    isForm?: boolean;
    phoneCode?: string;
    phoneNumber?: string;
    onCodeChange?: (value: string) => void;
    onPhoneChange?: (value: string) => void;
    selectSize?: 'small' | 'large';
}

const Phone: FC<IProps> = ({
    label,
    isForm = true,
    phoneCode,
    phoneNumber,
    onPhoneChange,
    onCodeChange,
    disabled,
    readOnly,
    section,
    selectSize = 'large'
}) => {
    return (
        <>
            {label && <label>{label}</label>}
            <div className={styles.wrapper}>
                {isForm ? (
                    <FormField
                        name={`${section ? `${section}.` : ''}phoneCode`}
                        renderComponent={({ value, onChange }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                disabled={disabled}
                                readOnly={readOnly}
                                selectSize={selectSize}
                            />
                        )}
                    />
                ) : (
                    <Select
                        value={phoneCode}
                        onChange={onCodeChange}
                        disabled={disabled}
                        readOnly={readOnly}
                        selectSize={selectSize}
                    />
                )}
                {isForm ? (
                    <FormField
                        name={`${section ? `${section}.` : ''}phoneNumber`}
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                readOnly={readOnly}
                                inputClassName={styles.input}
                                disabled={disabled}
                                mask="(999) 999 99 99"
                            />
                        )}
                    />
                ) : (
                    <Input
                        value={phoneNumber}
                        onChange={onPhoneChange}
                        full
                        readOnly={readOnly}
                        inputClassName={styles.input}
                        disabled={disabled}
                        mask="(999) 999 99 99"
                    />
                )}
            </div>
        </>
    );
};

export default Phone;
