import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import CardDataSection from '@sections/xeppt_card/card_data';
import TransactionsSection from '@sections/xeppt_card/transaction_data';

const XepptCardLayout = () => {
    const { id } = useParams();

    const {
        data: card,
        handleRequest: refetchCard,
        isLoading
    } = useApiQuery({
        method: () => apiWalletService.getCardById(id as string),
        condition: !!id
    });

    return (
        <div className={styles.wrapper}>
            <CardDataSection card={card} refetchCard={refetchCard} isCardLoading={isLoading} />
            <TransactionsSection />
        </div>
    );
};

export default XepptCardLayout;
