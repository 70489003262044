import React, { ButtonHTMLAttributes, FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon, TIconType } from '@components/icons';

interface IProps {
    variant?:
        | 'gold'
        | 'secondary'
        | 'primary'
        | 'icon'
        | 'icon-dark'
        | 'outlined'
        | 'outlined-dark'
        | 'outlined-red';
    size?: 'small' | 'normal' | 'large' | 'medium';
    notify?: boolean;
    full?: boolean;
    disabled?: boolean;
    leftIcon?: TIconType;
    rightIcon?: TIconType;
    children?: ReactNode;
    className?: string;
}

const cx = classNames.bind(styles);
const Button: FC<IProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    variant,
    size,
    children,
    className,
    leftIcon,
    rightIcon,
    disabled,
    full,
    notify,
    ...props
}) => {
    const rootStyles = useMemo(
        () => cx([styles.root, size, variant, { notify, disabled, full }, className]),
        [size, variant, disabled, full, notify, className]
    );

    return (
        <button className={rootStyles} {...props} disabled={disabled}>
            {leftIcon && <Icon name={leftIcon} width={24} height={24} />}
            {children}
            {rightIcon && <Icon name={rightIcon} width={24} height={24} />}
        </button>
    );
};

export default Button;
