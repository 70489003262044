import React, { FC } from 'react';
import successIllustration from '@svg/illustrations/success_money_add.svg';
import errorIllustration from '@svg/illustrations/broken_card.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useFormContext, useWatch } from 'react-hook-form';
import { prettifyAmount } from '@utils/index';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    success: boolean;
}

const FinishStep: FC<IProps> = ({ success }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.finish'
    });
    const { submitLocale } = useLocales();
    const { control } = useFormContext();
    const amount = useWatch({ name: 'amount', control });
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <img src={success ? successIllustration : errorIllustration} alt="illustration" />
            <p className={styles.title}>{success ? t('success') : t('sorry')}</p>
            <Typography variant="body1" className={styles.description}>
                {success
                    ? `${prettifyAmount(amount)} CAD ${t('success_description')}`
                    : t('error_description')}
            </Typography>
            <Button variant="primary" size="normal" onClick={() => navigate(routes.dashboard)}>
                {submitLocale('done')}
            </Button>
        </div>
    );
};

export default FinishStep;
