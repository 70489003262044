import React from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useTranslation } from 'react-i18next';
import { apiWalletService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { ECancelCardReason } from '@xeppt/xeppt-sdk/types';

const ManageCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'manage_card'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { onOpen, onClose } = useModalContext();

    const handleOpenUpdatePinModal = () => {
        onOpen({
            modalId: modalIds.PIN_VERIFICATION_PHONE,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.UPDATE_PIN,
                    onSubmit: (data: { id: string; code: string; pin: string }) => {
                        apiWalletService
                            .setCardPin({
                                cardId: data?.id,
                                code: data?.code,
                                pin: data?.pin
                            })
                            .then(() => {
                                onOpen({
                                    modalId: modalIds.SUCCESS_UPDATE_PIN,
                                    onSubmit: () =>
                                        onOpen({
                                            modalId: modalIds.MANAGE_CREDIT_CARD,
                                            onSubmit: onClose
                                        })
                                });
                            })
                            .catch(requestErrorLocale);
                    }
                })
        });
    };

    const handleOpenLimitsModal = () => {
        onOpen({
            modalId: modalIds.CARD_LIMITS,
            onSubmit: (data: { id: string; usageLimit: string }) => {
                apiWalletService
                    .updateCardLimit({
                        cardId: data?.id,
                        limit: Number(data?.usageLimit),
                        limitEndDate: new Date(),
                        limitStartDate: new Date()
                    })
                    .then(() => {
                        requestSuccessLocale('update_limits');
                        onClose();
                    })
                    .catch(requestErrorLocale);
            }
        });
    };

    const handleOpenReplaceCardModal = () => {
        onOpen({
            modalId: modalIds.REPLACE_CARD_REASON,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.USER_VERIFICATION_PHONE,
                    onSubmit: () =>
                        onOpen({
                            modalId: modalIds.REPLACE_CARD_SUBMIT,
                            onSubmit: (data: {
                                secureId: string;
                                code: string;
                                id: string;
                                cardHolder: string;
                                reasonId: number;
                            }) =>
                                apiWalletService
                                    .replaceCard(
                                        {
                                            cardId: data?.id,
                                            cardHolder: data?.cardHolder,
                                            reasonId: data?.reasonId
                                        },
                                        {
                                            id: data?.secureId,
                                            code: data?.code
                                        }
                                    )
                                    .then(() => {
                                        onOpen({
                                            modalId: modalIds.REPLACE_CARD_SUCCESS,
                                            onSubmit: () =>
                                                onOpen({
                                                    modalId: modalIds.MANAGE_CREDIT_CARD,
                                                    onSubmit: onClose
                                                })
                                        });
                                    })
                                    .catch(requestErrorLocale)
                        })
                })
        });
    };

    const handleOpenCancelCardModal = () => {
        // onOpen({
        //     modalId: modalIds.CANCEL_CARD_PHONE_VERIFICATION,
        //     onSubmit: () =>
        onOpen({
            modalId: modalIds.CANCEL_CARD_REASON,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.USER_VERIFICATION_PHONE,
                    onSubmit: () =>
                        onOpen({
                            modalId: modalIds.CANCEL_CARD_SUBMIT,
                            onSubmit: (data: {
                                secureId: string;
                                code: string;
                                id: string;
                                reasonId: ECancelCardReason;
                            }) =>
                                apiWalletService
                                    .cancelCard(data?.id, data?.reasonId, {
                                        id: data?.secureId,
                                        code: data?.code
                                    })
                                    .then(() => {
                                        onOpen({
                                            modalId: modalIds.CANCEL_CARD_SUCCESS,
                                            onSubmit: () =>
                                                onOpen({
                                                    modalId: modalIds.MANAGE_CREDIT_CARD,
                                                    onSubmit: onClose
                                                })
                                        });
                                    })
                                    .catch(requestErrorLocale)
                        })
                })
        });
        // });
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" weight="semibold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <button disabled className={styles.link} onClick={handleOpenUpdatePinModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="settings" />
                        </span>
                        <Typography variant="body1">{t('update_pin')} (disabled)</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button disabled className={styles.link} onClick={handleOpenLimitsModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="limit" />
                        </span>
                        <Typography variant="body1">{t('view_limits')} (disabled)</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button className={styles.link} onClick={handleOpenReplaceCardModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="sync" />
                        </span>
                        <Typography variant="body1">{t('replace_card')}</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
                <button className={styles.link} onClick={handleOpenCancelCardModal}>
                    <div className={styles.text_wrapper}>
                        <span>
                            <Icon name="delete" />
                        </span>
                        <Typography variant="body1">{t('cancel_card')}</Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
            </div>
        </div>
    );
};

export default ManageCardModal;
