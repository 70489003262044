import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

const ChangePasswordModal: FC<IModalComponentProps> = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'change_password'
    });
    const { labelLocale } = useLocales();
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <div className={styles.row}>
                    <Input
                        label={labelLocale('current_password')}
                        type="password"
                        full
                        placeholder="••••••••"
                    />
                </div>
                <div className={styles.row}>
                    <Input
                        label={labelLocale('new_password')}
                        type="password"
                        full
                        placeholder="••••••••"
                    />
                </div>
                <div className={styles.row}>
                    <Input
                        label={labelLocale('confirm_new_password')}
                        type="password"
                        full
                        placeholder="••••••••"
                    />
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
