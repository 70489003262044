import React, { FC, useEffect, useState } from 'react';
import { IModalComponentProps } from '@context/modal_context';
import KycDocuments from './kyc_documents';
import KycErrors from './kyc_errors';
import styles from './styles.module.scss';

const KycModal: FC<IModalComponentProps<{ isError: boolean; codes: string[] }>> = ({
    onClose,
    modalData
}) => {
    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);

    const handleNextStep = () => {
        setStep((state) => state + 1);
    };

    useEffect(() => {
        if (modalData?.isError) {
            setStep(1);
        }
        if (modalData?.codes) {
            setErrors(modalData.codes);
        }
    }, [modalData]);

    const renderStep = () => {
        switch (step) {
            case 0:
                return <KycDocuments onClose={onClose} handleNextStep={handleNextStep} />;
            case 1:
                return (
                    <KycErrors
                        onClose={onClose}
                        errors={errors}
                        handleStart={() => {
                            setStep(0);
                            setErrors([]);
                        }}
                    />
                );
        }
    };

    return <div className={styles.wrapper}>{renderStep()}</div>;
};

export default KycModal;
