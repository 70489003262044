import React, { FC } from 'react';
import styles from './styles.module.scss';
import TransactionItem from './transaction_item';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { TTransactionLite } from '@xeppt/xeppt-sdk/types';
import Skeleton from '@components/common/skeleton';

interface IProps {
    transactions: TTransactionLite[];
    isTransactionsLoading?: boolean;
}

const TransactionTable: FC<IProps> = ({ transactions, isTransactionsLoading }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'transaction_tables'
    });
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            {isTransactionsLoading ? (
                <>
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                </>
            ) : transactions.length === 0 ? (
                <div className={styles.empty_transactions}>
                    <Typography variant="body3">{t('empty')}</Typography>
                </div>
            ) : (
                transactions.map((item, i) => (
                    <TransactionItem
                        key={i}
                        transaction={item}
                        onClick={(id) => navigate(`${routes.transactions}/${id}`)}
                    />
                ))
            )}
        </div>
    );
};

export default TransactionTable;
