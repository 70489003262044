import React, { FC, useEffect, useState } from 'react';
import ProgressBar from '@components/progress_bar';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import FirstStepLinkBank from '@sections/link_card_bank/link_bank/step1';
import SecondStepInstantLinkBank from '@sections/link_card_bank/link_bank/step2_instant';
import ThirdStepLinkBank from '@sections/link_card_bank/link_bank/step3';
import SecondStepManualLinkBank from '@sections/link_card_bank/link_bank/step2_manual';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import { defaultLinkBank } from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { linkBankValidation } from '@helpers/validation_objects/link_card_or_bank';
import { FormProvider } from 'react-hook-form';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiAccountService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import axios from 'axios';
import { toast } from 'react-toastify';

const steps = [
    {
        title: 'Linking methods',
        id: '1'
    },
    {
        title: 'Verification',
        id: '2'
    },
    {
        title: 'Complete',
        id: '3'
    }
];

interface IProps {
    handleChoseOtherType: () => void;
}

const LinkBankSection: FC<IProps> = ({ handleChoseOtherType }) => {
    const navigate = useNavigate();
    const { validationLocale, submitLocale, requestErrorLocale, requestSuccessLocale } =
        useLocales();
    const { currentStep, availableStep, nextStep, changeStep } = useProgressBar(steps);
    const [linkMethod, setLinkMethod] = useState<'manual' | 'instant'>('instant');
    const [preventLinkMethod, setPreventLinkMethod] = useState<'manual' | 'instant'>('instant');
    const [isDone, setIsDone] = useState(false);
    const { user, refetchAccount } = useUserContext();
    const formMethods = useForm({
        defaultValues: defaultLinkBank,
        resolver: zodResolver(
            linkBankValidation({
                method: linkMethod,
                messages: {
                    accountNumber: validationLocale('account_number'),
                    transitNumber: validationLocale('transit_number'),
                    accountName: validationLocale('account_name'),
                    bankNumber: validationLocale('bank_number')
                }
            })
        )
    });

    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const loginId = queryParams.get('loginId');
        const accountId = queryParams.get('accountId');

        if (loginId && accountId && user?.id) {
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_URL}/webhook/flinks-connect/${user?.id}?loginId=${loginId}&accountId=${accountId}`
                )
                .then(() => {
                    changeStep('3');
                    refetchAccount();
                })
                .catch(() => {
                    toast.error('Something went wrong, please try again');
                });
        }
    }, [user]);

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <FirstStepLinkBank
                        handleChoseOtherType={handleChoseOtherType}
                        type={preventLinkMethod}
                        onChange={(value) => setPreventLinkMethod(value)}
                    />
                );
            case '2':
                return linkMethod === 'manual' ? (
                    <SecondStepManualLinkBank
                        handleChooseOtherMethod={() => setLinkMethod('instant')}
                    />
                ) : (
                    <SecondStepInstantLinkBank
                        handleChooseOtherMethod={() => setLinkMethod('manual')}
                    />
                );
            case '3':
                return <ThirdStepLinkBank />;
        }
    };

    const handleLinkBankAccount = () => {
        const { accountNumber, transitNumber, accountName, bankNumber } = formMethods.getValues();
        apiAccountService
            .linkBankAccount({
                accountName,
                transitNumber: Number(transitNumber),
                accountNumber: Number(accountNumber),
                bankNumber: Number(bankNumber)
            })
            .then(() => {
                requestSuccessLocale('link_bank_account');
                refetchAccount();
                nextStep();
                setIsDone(true);
            })
            .catch(requestErrorLocale);
    };

    return (
        <div className={styles.wrapper}>
            <FormProvider {...formMethods}>
                <ProgressBar
                    steps={steps}
                    availableStep={availableStep}
                    currentStep={currentStep}
                    size="large"
                    variant="light"
                    className={styles.progressbar}
                />
                <div className={styles.content}>{renderStep()}</div>
                {((linkMethod !== 'instant' && currentStep?.id !== '2') ||
                    currentStep?.id === '1' ||
                    linkMethod === 'manual') && (
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={() => {
                            if (currentStep?.id === '1') {
                                setLinkMethod(preventLinkMethod);
                                nextStep();
                            } else if (currentStep?.id === '2') {
                                handleLinkBankAccount();
                            } else {
                                navigate(routes.dashboard);
                            }
                        }}
                        disabled={!formMethods.formState.isValid}>
                        {isDone ? submitLocale('done') : submitLocale('next')}
                    </Button>
                )}
            </FormProvider>
        </div>
    );
};

export default LinkBankSection;
