import React from 'react';
import AccountsSection from '@sections/dashboard/accounts';
import styles from './styles.module.scss';
import BalanceSection from '@sections/dashboard/balance';
import CardsSection from '@sections/dashboard/cards';
import TransactionsSection from '@sections/dashboard/transactions';

const DashboardLayout = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <BalanceSection />
                <CardsSection />
                <TransactionsSection />
            </div>
            <div className={styles.right}>
                <AccountsSection />
            </div>
        </div>
    );
};

export default DashboardLayout;
