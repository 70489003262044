import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import CardChoose from '@components/cards/card_choose';
import { useTranslation } from 'react-i18next';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';

interface IProps {
    type: ETransactionMethod;
    onChange: (value: ETransactionMethod) => void;
}

const PaymentMethodStep: FC<IProps> = ({ type, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.payment_type'
    });
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                {/*<CardChoose*/}
                {/*    fees={0.79}*/}
                {/*    type="card"*/}
                {/*    checked={type === 'card'}*/}
                {/*    onClick={() => onChange('card')}*/}
                {/*/>*/}
                <CardChoose
                    fees={0.5}
                    type={ETransactionMethod.EFT}
                    checked={type === ETransactionMethod.EFT}
                    onClick={() => onChange(ETransactionMethod.EFT)}
                />
                <CardChoose
                    fees={1}
                    type={ETransactionMethod.E_TRANSFER}
                    checked={type === ETransactionMethod.E_TRANSFER}
                    onClick={() => onChange(ETransactionMethod.E_TRANSFER)}
                />
            </div>
        </div>
    );
};

export default PaymentMethodStep;
