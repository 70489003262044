import {
    ECardStatus,
    ECardType,
    ECurrency,
    EOrderStatus,
    EPaymentSystem,
    ETransactionType
} from '@enum';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';

export type TAccount = {
    name: string;
    card: number;
};

export type TBalance = {
    currency: ECurrency;
    amount: number;
};

export type TCard = {
    status: ECardStatus;
    currency: ECurrency;
    amount: number;
    system: EPaymentSystem;
    card: string;
    card_image: string;
    name: string;
    orderStatus?: EOrderStatus;
    type: ECardType;
};

export type TCardOrder = {
    status: EOrderStatus;
    type: ECardType;
};

export type TTransaction = {
    type: ETransactionType;
    name: string;
    id: string;
    date: number;
    amount: number;
    isPay: boolean;
    currency: ECurrency;
};

export type TAddress = {
    country: string;
    region: string;
    city: string;
    address2: string;
    address1: string;
    zipCode: string;
};

export type TBusinessDetails = {
    companyName: string;
    tradingName: string;
    registrationNumber: string;
    category: string;
    size: string;
    website?: string;
};

//--------------- GUARDS ---------------//

export const isBusinessUserTypeGuard = (user: TIndividualProfile | TBusinessProfile): user is TBusinessProfile => {
    return 'business' in user
}
