import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    onChange?: (value: File) => void;
    label?: string;
    preview?: string;
    disabled?: boolean;
}

const DragAndDrop: FC<IProps> = ({ onChange, label, preview, disabled }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'drag_and_drop'
    });
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const dropRef = useRef<HTMLDivElement>(null);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropRef.current) {
            dropRef.current.classList.add(styles.dragOver);
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropRef.current) {
            dropRef.current.classList.remove(styles.dragOver);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropRef.current) {
            dropRef.current.classList.remove(styles.dragOver);
        }
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
                if (event.target && event.target.result) {
                    setImageSrc(event.target.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
        onChange && onChange(file);
    };

    const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
                if (event.target && event.target.result) {
                    setImageSrc(event.target.result as string);
                }
            };
            reader.readAsDataURL(file);
            onChange && onChange(file);
        }
    }, []);

    useEffect(() => {
        if (preview) {
            setImageSrc(preview);
        }
    }, [preview]);

    return (
        <label className={styles.wrapper}>
            <div
                ref={dropRef}
                className={`${styles.dropZone} ${disabled ? styles.isDisabled : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                {imageSrc ? (
                    <img className={styles.preview} src={imageSrc} alt="Preview" />
                ) : (
                    <div>
                        <Icon width={50} height={50} name="camera" />
                        <p>{label || t('upload')}</p>
                    </div>
                )}
            </div>
            <input
                type="file"
                accept="image/*"
                disabled={disabled}
                onChange={handleFileSelect}
                className={styles.fileInput}
            />
        </label>
    );
};

export default DragAndDrop;
