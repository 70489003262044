import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import styles from '../profile/styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import { getFirstLetter } from '@utils/index';
import { useForm } from '@hooks/helpers/useForm';
import { defaultBusinessData } from '@const/default_form_data';
import { FormProvider } from 'react-hook-form';
import AddressSection from '@sections/settings/address';
import { zodResolver } from '@hookform/resolvers/zod';
import { businessSettingsValidation } from '@helpers/validation_objects/profile_settings';
import { EAddressType, EUserType, TBusinessAddress } from '@xeppt/xeppt-sdk/types/user';
import { TBusinessDetails } from '@types';
import BusinessSection from '@sections/settings/business';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';

const BusinessLayout = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'businessProfile'
    });
    const navigate = useNavigate();
    const { validationLocale, requestErrorLocale, labelLocale, requestSuccessLocale } =
        useLocales();
    const { user, businessData, refetchUser } = useUserContext();
    const { onOpen, onClose } = useModalContext();
    const formMethods = useForm<TBusinessDetails>({
        defaultValues: defaultBusinessData,
        resolver: zodResolver(
            businessSettingsValidation({
                messages: {
                    companyName: validationLocale('companyName'),
                    tradingName: validationLocale('tradingName'),
                    registrationNumber: validationLocale('registrationNumber'),
                    category: validationLocale('category'),
                    size: validationLocale('size'),
                    website: validationLocale('website')
                }
            })
        )
    });

    useEffect(() => {
        if (user?.type === EUserType.BUSINESS) {
            formMethods.reset(businessData);
        } else {
            navigate(routes.settings);
        }
    }, [user]);

    const { data } = useApiQuery({
        method: () => apiUserService.getAddress()
    });

    const handleChangeAvatar = () => {
        onOpen({
            modalId: modalIds.SETTINGS_UPLOAD_IMAGE,
            onSubmit: (data: { avatar: string }) => {
                apiUserService
                    .updateAvatar(data.avatar)
                    .then(() => {
                        refetchUser();
                        onClose();
                        requestSuccessLocale('update_avatar');
                    })
                    .catch(requestErrorLocale);
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <div className={styles.avatar_wrapper}>
                        <div>
                            {user?.profile?.avatar ? (
                                <img src={user?.profile?.avatar} alt="your avatar" />
                            ) : (
                                <Typography variant="h3">
                                    {getFirstLetter(businessData?.companyName)}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <button onClick={handleChangeAvatar}>
                                <Typography variant="body1">
                                    {t(user?.profile?.avatar ? 'update_logo' : 'add_logo')}
                                </Typography>
                            </button>
                            {user?.profile?.avatar && (
                                <button onClick={handleChangeAvatar}>
                                    <Typography variant="body1">{t('delete_logo')}</Typography>
                                </button>
                            )}
                        </div>
                    </div>
                    <BusinessSection />
                    <AddressSection
                        label={labelLocale('registrationAddress')}
                        name="registrationAddress"
                        type={EAddressType.REGISTRATION}
                        data={data as TBusinessAddress}
                    />
                    <AddressSection
                        label={labelLocale('tradingAddress')}
                        name="tradingAddress"
                        type={EAddressType.TRADING}
                        data={data as TBusinessAddress}
                    />
                    <AddressSection
                        label={labelLocale('businessAddress')}
                        name="businessAddress"
                        type={EAddressType.BUSINESS}
                        data={data as TBusinessAddress}
                    />
                </div>
            </FormProvider>
        </div>
    );
};

export default BusinessLayout;
